<template>
  <div class="req-temp-container integration-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">{{ pageHeader }}</h3>
        </div>
      </div>

      <div class="mt-2">
        <!-------Authentication section------->
        <template v-if="step == 1">
          <div class="mb-8">
            <p class="text-gray-font">
              {{ appName }} integrates with {{ activeIntroducer.name }} via HiCaliber. This service is completely free for you, we'll handle everything, but {{ activeIntroducer.name }} does
              require explicit confirmation from your account owner that you're allowing {{ appName }}/HiCaliber to access your {{ activeIntroducer.name }} account via API.
            </p>
            <p class="text-gray-font mt-4">
              Please provide the information below and we'll automatically email {{ activeIntroducer.name }} and HiCaliber, who will contact you shortly to verify the access request.
              Once confirmed, we'll sync your {{ activeIntroducer.name }} account with {{ appName }}, and ask you to login back in to finalise some simple settings.
            </p>
            <div class="w-2/3 mt-4">
              <div class="flex w-full mb-4 mt-8">
                <label>{{ activeIntroducer.name }} account owner name:</label>
                <vs-input
                  class="w-full text-gray ml-4"
                  type="text"
                  v-model="rex.name"
                  name="accountName"
                  v-validate="'required'"
                  data-vv-as="account owner name"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('accountName')">{{ errors.first("accountName") }}</span>
            </div>
            <div class="w-2/3">
              <div class="flex w-full my-4">
                <label>Email address:</label>
                <vs-input
                  class="w-full text-gray ml-4"
                  type="text"
                  v-model="rex.email"
                  name="emailAddress"
                  v-validate="'required|email'"
                  data-vv-as="email address"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('emailAddress')">{{ errors.first("emailAddress") }}</span>
            </div>
            <div class="w-2/3 mt-4">
              <div class="flex w-full mb-4 mt-8">
                <label>Offices to sync:</label>
                <vs-textarea
                  class="custom-textarea custom-message integration-text ml-4"
                  v-model="rex.syncOffices"
                  id="custom-textarea"
                  @input="auto_grow('custom-textarea')"
                  name="syncOffices"
                  v-validate="'required'"
                  data-vv-as="offices to sync"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('syncOffices')">{{ errors.first("syncOffices") }}</span>
            </div>
            <div class="mt-4">
              <integration-terms />
              <div class="grid grid-cols-2">
                <div class="flex mt-4">
                  <vs-checkbox name="integrationTerm" id="integrationTerm" data-vv-as="Terms and Conditions" v-validate="'required'" v-model="rex.terms" class="integration-terms"></vs-checkbox>
                  <span class="mt-1">I accept the terms & conditions</span>
                </div>
                <div class="flex justify-end">
                  <vs-button size="large" action-btn v-round :disabled="disableButtons" @click="addIntroducer()">Submit</vs-button>
                  <vs-button @click="goBack()" size="large" action-btn class="only-border-btn ml-2" v-round>Cancel</vs-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-------Authentication section------->

        <!-------Default section------->
        <template v-if="step == 2">
          <!----Disbursement---->
          <div class="mb-8">
            <p class="text-sm mb-4">Please select a default bank account for {{ appName }} to disburse funds into (for approved VPA agreements). Where no other information is available, we'll disburse to this account.</p>
            <div class="input-grp">
              <vs-select
                v-model="rex.disbursement"
                name="disbursementAccount"
                id="disbursementAccount"
                class="w-full selectExample pr-8 mb-0"
                v-validate="'required'"
                :placeholder="'Select account'"
                data-vv-as="disbursement account"
              >
                <vs-select-item
                  :key="index"
                  :value="item.bankId"
                  :text="item.displayName ? item.displayName : item.accountName"
                  v-for="(item, index) in bankList"
                />
              </vs-select>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('disbursementAccount')">{{ errors.first("disbursementAccount") }}</span>
          </div>
          <!------Notification------->
          <div class="mb-12">
            <p class="text-sm mb-4">Please select a default recipient/s for automated notifications about your vendor agreements (optional)</p>
            <div class="flex">
              <div class="w-1/2">
                <div v-for="(newEmail, index) in rex.notifications" :key="index" class="flex flex-row mb-2">
                  <div class="mr-2">
                    <vs-input
                      size="large"
                      v-model="newEmail.email"
                      data-vv-validate-on="blur"
                      data-vv-as="email address"
                      :name="'defaultNotification'+index"
                      :id="'notification'+index"
                      class="w-full text-gray"
                      v-validate="'email'"
                      autocomplete="off"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('defaultNotification'+index)">{{ errors.first("defaultNotification"+index) }}</span>
                  </div>
                  <feather-icon v-if="deleteEmail(index)" icon="Trash2Icon" height="18px" @click="removeEmail(index)" class="text-grey integration-icon pt-2" />
                </div>
                <span @click="addEmail" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
              </div>
            </div>
          </div>
          <!-------Remittance-------->
          <div class="mb-12">
            <p class="text-sm mb-4">Please select a default recipient/s for automated remittance advice per vendor agreement (optional)</p>
            <div class="flex">
              <div class="w-1/2">
                <div v-for="(remittanceEmail, index) in rex.remittance" :key="index" class="flex flex-row mb-2">
                  <div class="mr-2 w-1/2">
                    <vs-input
                      size="large"
                      v-model="remittanceEmail.email"
                      data-vv-validate-on="blur"
                      data-vv-as="email address"
                      :name="'defaultRemittance'+index"
                      :id="'remittance'+index"
                      class="w-full text-gray"
                      v-validate="'email'"
                      autocomplete="off"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('defaultRemittance'+index)">{{ errors.first("defaultRemittance"+index) }}</span>
                  </div>
                  <feather-icon v-if="deleteDefaultRemittance(index)" icon="Trash2Icon" @click="removeDefaultRemittance(index)" class="text-grey integration-icon pt-2" />
                </div>
                <span @click="addDefaultRemittance" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
              </div>
            </div>
          </div>
          <!-----Payment indicator------>
          <div class="mb-16">
            <p class="text-sm mt-12 mb-4">
              Optionally add "PNOW" or "PLTR" to the bank transfer reference, to indicate nature of the payment on your bank statement.</p>
            <div class="flex w-full my-4">
              <label>Append PNOW/PLTR indicator</label>
              <vs-switch name="appendPaymentType" v-model="rex.appendPaymentType" class="ml-4" />
            </div>
          </div>
        </template>
        <!-------Default section------->

        <!-------Offices section------->
        <template v-if="step == 3">
          <div class="mb-8">
            <p class="text-sm mb-4">
              Select the offices within {{ activeIntroducer.name }} to sync to this Rello merchant account (each office can only be synced to one merchant account).
              You can override the default settings entered earlier, per office. If no value is entered for notifications/remittance, we won't send these emails.
            </p>
            <span class="text-danger text-sm" v-if="officeWarning">All offices are already related to other merchant accounts - contact your related offices to resolve.</span>
            <vs-table :data="rex.offices" class="stripes no-row-border-table payment-request-table payment-activity-table integration-table">
              <template slot="thead">
                <vs-th width="18%">
                  <label class="m-0 p-0 font-normal">Office</label>
                </vs-th>
                <vs-th width="10%" class="custom-center">
                  <label class="m-0 p-0 font-normal">Sync</label>
                </vs-th>
                <vs-th width="72%">
                  <label class="m-0 p-0 font-normal">Settings</label>
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td>
                  <div class="flex flex-col gap-y-10">
                    <p class="office-name">{{ tr.name }}</p>
                    <a v-if="tr.canSync && tr.syncOffice" @click="resetToDefault($event, indextr, tr)" class="no-underline text-interaction-primary block text-sm flex mt-4 items-center">
                      <vs-icon icon-size="small" class="refresh-icon mr-2">refresh</vs-icon>
                      Reset to default settings
                    </a>
                  </div>
                  </vs-td>
                  <vs-td>
                    <vs-checkbox v-model="tr.syncOffice" name="syncOffice" :disabled="!tr.canSync" @change="handleOfficeSync(indextr, tr)" />
                    <p v-if="!tr.canSync && tr.merchant" class="text-small text-center mt-2">
                      {{ tr.merchant.merchantId }}<br/>
                      {{ tr.merchant.companyName }}
                    </p>
                  </vs-td>
                  <!----Disbursement account---->
                  <vs-td :disabled="isOfficeDisabled(tr)">
                    <div v-if="tr.canSync" class="grid grid-cols-3 grid-flow-row gap-8">
                      <!----Disbursement account---->
                      <div>
                        <div>
                          <label>Disburse to</label>
                          <vs-select
                            v-model="tr.disbursement"
                            id="officeDisbursementAccount"
                            class="w-full mt-2"
                            data-vv-as="disbursement account"
                            :name="'officeDisbursementAccount'+indextr"
                            :placeholder="'Select account'"
                            :disabled="isOfficeDisabled(tr)"
                          >
                            <vs-select-item
                              :key="index"
                              :value="item.bankId"
                              :text="item.displayName ? item.displayName : item.accountName"
                              v-for="(item, index) in bankList"
                            />
                          </vs-select>
                          <span class="text-danger text-sm" v-show="errors.has('officeDisbursementAccount'+indextr)">{{ errors.first("officeDisbursementAccount"+indextr) }}</span>
                        </div>
                        <!------Payment indicator----->
                        <div class="flex w-full mt-12 mb-4">
                          <vs-switch
                            v-model="tr.appendPaymentType" class="mr-4"
                            :name="`appendPaymentType${indextr}`"
                            :vs-name="`appendPaymentType${indextr}`"
                            :disabled="isOfficeDisabled(tr)"
                          />
                          <label>Append PNOW/PLTR indicator</label>
                        </div>
                      </div>
                      <!------Notification----->
                      <div>
                        <label>Notifications</label>
                        <div v-if="(tr.sendNotificationTo != 'REQUEST_USER')">
                          <div v-for="(notificationEmail, index) in tr.notifications" :key="index + 0.5" class="flex flex-row mb-2">
                            <div class="mr-2" :disabled="isOfficeDisabled(tr)">
                              <vs-input
                                size="large"
                                v-model="notificationEmail.email"
                                data-vv-validate-on="blur"
                                data-vv-as="email address"
                                class="w-full text-gray no-max-width"
                                v-validate="'email'"
                                autocomplete="off"
                                :name="'notificationEmail'+indextr+index"
                                :id="'notificationEmail'+indextr+index"
                                :disabled="isOfficeDisabled(tr)"
                              />
                              <span class="text-danger text-sm" v-show="errors.has('notificationEmail'+indextr+index)">{{ errors.first("notificationEmail"+indextr+index) }}</span>
                            </div>
                            <feather-icon
                              v-if="deleteOfficeNotificationEmail(indextr) && tr.canSync && tr.syncOffice"
                              icon="Trash2Icon"
                              class="text-grey integration-icon pt-2"
                              @click="removeOfficeNotificationEmail(indextr, index)"
                            />
                          </div>
                          <span v-if="tr.canSync && tr.syncOffice" @click="addOfficeNotificationEmail(indextr)" class="text-link flex flex-row pt-2">
                            <plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address
                          </span>
                        </div>
                        <div class="flex flex-col gap-y-2 custom-radio mt-4">
                          <label>Send notification to:</label>
                          <vs-radio v-model="tr.sendNotificationTo" :vs-name="`sendNotificationTo${indextr}`" vs-value="BOTH" :disabled="isOfficeDisabled(tr)">
                            Send to selected addresses AND PR creator, if available
                          </vs-radio>
                          <vs-radio v-model="tr.sendNotificationTo" :vs-name="`sendNotificationTo${indextr}`" vs-value="SELECTED_ADDRESS" :disabled="isOfficeDisabled(tr)">
                            Only send to selected addresses
                          </vs-radio>
                          <vs-radio v-model="tr.sendNotificationTo" :vs-name="`sendNotificationTo${indextr}`" vs-value="REQUEST_USER" :disabled="isOfficeDisabled(tr)">
                            Only send to PR creator, if available
                          </vs-radio>
                        </div>
                      </div>
                      <!------Remittance----->
                      <div>
                        <label>Remittance</label>
                        <div v-if="(tr.sendRemittanceTo != 'REQUEST_USER')">
                          <div v-for="(remittanceEmail, index) in tr.remittance" :key="index + 0.15" class="flex flex-row mb-2">
                            <div class="mr-2">
                              <vs-input
                                size="large"
                                v-model="remittanceEmail.email"
                                data-vv-validate-on="blur"
                                data-vv-as="email address"
                                class="w-full text-gray no-max-width"
                                v-validate="'email'"
                                autocomplete="off"
                                :name="'remittanceEmail'+indextr+index"
                                :id="'remittanceEmail'+indextr+index"
                                :disabled="isOfficeDisabled(tr)"
                              />
                              <span class="text-danger text-sm" v-show="errors.has('remittanceEmail'+indextr+index)">{{ errors.first("remittanceEmail"+indextr+index) }}</span>
                            </div>
                            <feather-icon
                              v-if="deleteOfficeRemittanceEmail(indextr)"
                              icon="Trash2Icon"
                              @click="removeOfficeRemittanceEmail(indextr, index)"
                              class="text-grey integration-icon pt-2"
                            />
                          </div>
                          <span v-if="tr.canSync && tr.syncOffice" @click="addOfficeRemittanceEmail(indextr)" class="text-link flex flex-row pt-2">
                            <plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address
                          </span>
                        </div>
                        <div class="flex flex-col gap-y-2 custom-radio mt-4">
                          <label>Send remittance to:</label>
                          <vs-radio v-model="tr.sendRemittanceTo" :vs-name="`sendRemittanceTo${indextr}`" vs-value="BOTH" :disabled="isOfficeDisabled(tr)">
                            Send to selected addresses AND PR creator, if available
                          </vs-radio>
                          <vs-radio v-model="tr.sendRemittanceTo" :vs-name="`sendRemittanceTo${indextr}`" vs-value="SELECTED_ADDRESS" :disabled="isOfficeDisabled(tr)">
                            Only send to selected addresses
                          </vs-radio>
                          <vs-radio v-model="tr.sendRemittanceTo" :vs-name="`sendRemittanceTo${indextr}`" vs-value="REQUEST_USER" :disabled="isOfficeDisabled(tr)">
                            Only send to PR creator, if available
                          </vs-radio>
                        </div>
                      </div>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <span class="text-danger text-sm" v-show="errors.has('officeSync')">{{ errors.first("officeSync") }}</span>
          </div>
        </template>

        <div class="popup-action-btn-container bottom-0 bg-white" v-if="[2, 3].includes(step)">
          <div class="flex justify-end">
            <vs-button v-if="step == 3" size="large" action-btn v-round @click="updateSettings()" :disabled="errors.items.length > 0">
              Save
            </vs-button>
            <vs-button v-else size="large" action-btn v-round @click="nextStep()" :disabled="disableButtons">Next</vs-button>
            <vs-button @click="goBack()" size="large" action-btn class="only-border-btn ml-2" v-round>Cancel</vs-button>
          </div>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import IntegrationTerms from "./IntegrationTerms.vue";

export default {
  components: { IntegrationTerms, PlusIcon },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      action: "",
      activeIntroducer: {},
      bankList: [],
      officeWarning: false,
      pageHeader: "",
      merchant: {},
      merchantId: "",
      introducerId: "",
      step: 1,
      rex: {
        name: "",
        email: "",
        syncOffices: "All offices",
        terms: false,
        offices: [],
        disbursement: "",
        notifications: [{ email: "" }],
        remittance: [{ email: "" }],
        appendPaymentType: false,
      },
    };
  },

  methods: {
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("introducer", ["fetchIntroducerMerchantLink"]),
    ...mapActions("linkRequest", ["createIntroducerLinkRequest"]),
    ...mapActions("partner", ["getRexMalcomOffices", "updateRexSettings"]),

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then(result => {
        this.$vs.loading.close();
        this.merchant = result.data.data;

        if (this.action == "edit") {
          this.handleEdit();
        }
      });
    },

    async handleEdit() {
      const connection = this.merchant.rex.connections.find((item) => item.introducerId == this.introducerId);

      if (connection) {
        let merchantOffices = [...connection.offices];
        let latestOffice = [];

        const defaultConnection = (merchantOffices && merchantOffices.length) ? merchantOffices.find((item) => item.name == "default") : {};
        const existingOfficeIds = (merchantOffices && merchantOffices.length) ? merchantOffices.map(item => item.officeId).filter(item => item) : [];

        const data = {
          partnerId: this.partnerId,
          payload: {
            parentId: connection.parentId,
            merchantId: this.merchantId,
          }
        };

        this.$vs.loading();
        await this.getRexMalcomOffices(data).then((result) => {
          const updatedOffices = result.data.data.data; // offices from latest api call
          const updatedOfficeIds = updatedOffices.map(item => item.id);

          // get array of office id from existing offices that doesn't exist in updated list
          const deletedOffice = existingOfficeIds.filter(id => !updatedOfficeIds.includes(id));

          // remove deleted office from merchantOffices array
          for (let i = 0; i < deletedOffice.length; i++) {
            const removeIndex = merchantOffices.findIndex((item) => item.officeId == deletedOffice[i]);

            if (removeIndex >= 0) {
              merchantOffices.splice(removeIndex, 1);
            }
          }

          for (let i = 0; i < updatedOffices.length; i++) {
            const officeData = updatedOffices[i];
            const officeIndex = merchantOffices.findIndex((item) => item.officeId == officeData.id); // get index of office as per current configuration

            // if office id not found, add office to array
            if (officeIndex < 0) {
              latestOffice.push({
                name: officeData.name,
                officeId: officeData.id,
                disbursement: "",
                notifications: [],
                remittance: [],
                sendNotificationTo: "",
                sendRemittanceTo: "",
                appendPaymentType: false,
                merchant: officeData.merchant,
                canSync: officeData.canSync,
                syncOffice: officeData.syncOffice,
              });
            } else if (officeIndex >= 0 && (merchantOffices[officeIndex].name != officeData.name)) { // if office exists, but office name is updated, update name in array
              latestOffice.push({
                ...merchantOffices[officeIndex],
                name: officeData.name,
                syncOffice: true,
                canSync: true,
              });
            } else {
              latestOffice.push({
                ...merchantOffices[officeIndex],
                syncOffice: true,
                canSync: true,
              });
            }
          }
        }).catch((ex) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: "Rex integration",
            text: ex.data.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
        
        const defaultNotification = (defaultConnection.notifications && defaultConnection.notifications.length) ? defaultConnection.notifications.filter((item) => item) : [];
        const defaultRemittance = (defaultConnection.remittance && defaultConnection.remittance.length) ? defaultConnection.remittance.filter((item) => item) : [];

        this.rex = {
          introducerId: connection.introducerId,
          disbursement: (defaultConnection && defaultConnection.disbursement) ? defaultConnection.disbursement : "",
          notifications: defaultNotification.length ? defaultNotification.map((item) => { return { email: item }}) : [{ email: "" }],
          remittance: defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item }}) : [{ email: "" }],
          appendPaymentType: defaultConnection.appendPaymentType || false,
          offices: latestOffice.map((item) => {
            return {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: item.notifications.length ? item.notifications.map(item => { return { email: item } }) : [{ email: "" }],
              remittance: item.remittance.length ? item.remittance.map(item => { return { email: item } }) : [{ email: "" }],
              sendNotificationTo: item.sendNotificationTo,
              sendRemittanceTo: item.sendRemittanceTo,
              appendPaymentType: item.appendPaymentType || false,
              merchant: item.merchant || null,
              canSync: item.canSync || false,
              syncOffice: item.syncOffice || false,
            }
          })
        }

        this.$vs.loading.close();
      }
    },

    async getIntroducerByMerchantId() {
      await this.fetchIntroducerMerchantLink({ merchantId: this.partnerId, introducerId: this.introducerId }).then((res) => {
        this.activeIntroducer = res.data.data;

        if (this.action == "create" && !this.activeIntroducer.isRexEnabled) {
          this.showToastMessage("Error", "Introducer has not been enabled for Rex integration", "error");
          this.goBack();
        } else if (this.action == "create" && this.activeIntroducer.linkRequest) {
          this.showToastMessage("Error", "Introducer has already been authorised for integration", "error");
          this.goBack();
        } else if (this.action == "edit" && !this.activeIntroducer.linkRequest) {
          this.showToastMessage("Error", "Introducer has not been linked with your merchant", "error");
          this.goBack();
        }

        // Update route title as introducer name
        const title = `${this.activeIntroducer.name} - settings`;

        this.$route.meta.pageTitle = title;
        this.$route.meta.breadcrumb.push({ title: title, active: true });
        this.$emit("changeRouteTitle", title);

        if (this.step == 1) {
          this.pageHeader = `Authorise API access to ${this.activeIntroducer.name}`;
        } else if (this.step == 2) {
          this.pageHeader = "Default settings";
        }

        this.getBanks();
      }).catch(ex => {
        this.showToastMessage("Introducers", ex.data.message, "error");
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: "integrations" });
    },

    auto_grow(elem) {
      let element = document.getElementById(elem);

      if (element) {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight) + "px";
      }
    },

    async addIntroducer() {
      let obj = {
        merchantId: this.partnerId,
        introducerId: this.introducerId,
      };

      if (this.activeIntroducer.isRexEnabled) {
        const validate = await this.$validator.validateAll();

        if (!validate || this.errors.items.length) {
          return false;
        }

        obj.rex = JSON.stringify({
          introducerId: this.introducerId,
          name: this.rex.name,
          email: this.rex.email,
          syncOffices: this.rex.syncOffices,
          integrationTerms: this.rex.terms
        });

        this.$vs.loading();

        await this.createIntroducerLinkRequest(obj).then((res) => {
          this.$vs.loading.close();
          this.showToastMessage(res.data.title, res.data.message, "success");
          this.goBack();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Link request", ex.data.message, "error");
        });
      }
    },

    async getBanks() {
      await this.fetchBanksAccountByPartnerId(this.partnerId).then((result) => {
        if (result) {
          const banksList = result.data.data;
          this.bankList = banksList.filter(item => item.isUsedForSettlement).map((item) => {
            return {
              bankId: item._id,
              displayName: item.displayName ? item.displayName : item.accountName,
            };
          });
        }
      }).catch((ex) => {
        this.$vs.notify({
          title: "Bank account",
          text: "We are unable to process your request at this time.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    removeEmail(index) {
      this.rex.notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultNotification" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    addEmail() {
      this.rex.notifications.push({ email: "" });
    },

    deleteEmail() {
      return this.rex.notifications.length > 1;
    },

    addDefaultRemittance() {
      this.rex.remittance.push({ email: "" });
    },

    removeDefaultRemittance(index) {
      this.rex.remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultRemittance" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    deleteDefaultRemittance() {
      return this.rex.remittance.length > 1;
    },

    async nextStep() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      this.step += 1;

      if (this.step == 3) {
        this.pageHeader = "Configure settings for each office";
        const canSync = this.rex.offices.filter((item) => item.canSync);

        if (!canSync || !canSync.length) {
          this.officeWarning = true;
        }
      }

      window.scrollTo(0, 0);
    },

    handleOfficeSync(index, data) {
      if (data.syncOffice) {
        if (this.errors.has("officeSync")) {
          this.errors.remove("officeSync");
        }

        // Assign default values
        let defaultNotifications = this.rex.notifications.map(item => { return item.email }).filter((item) => item);
        defaultNotifications = defaultNotifications.length ? defaultNotifications.map((item) => { return { email: item } }) : [{ email: "" }];

        let defaultRemittance = this.rex.remittance.map(item => { return item.email }).filter((item) => item);
        defaultRemittance = defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item } }) : [{ email: "" }];

        this.rex.offices[index].disbursement = this.rex.disbursement;
        this.rex.offices[index].notifications = JSON.parse(JSON.stringify(defaultNotifications));        
        this.rex.offices[index].remittance = JSON.parse(JSON.stringify(defaultRemittance));
        this.rex.offices[index].sendNotificationTo = "BOTH";
        this.rex.offices[index].sendRemittanceTo = "BOTH";
        this.rex.offices[index].appendPaymentType = this.rex.appendPaymentType;
      } else {
        this.rex.offices[index].disbursement = "";
        this.rex.offices[index].notifications = [{ email: "" }];
        this.rex.offices[index].remittance = [{ email: "" }];
        this.rex.offices[index].sendNotificationTo = "";
        this.rex.offices[index].sendRemittanceTo = "";
        this.rex.offices[index].appendPaymentType = false;
      }
    },

    resetToDefault(event, index, data) {
      if (!data.syncOffice) {
        // Prevent default behavior and stop propagation
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      this.rex.offices[index].disbursement = this.rex.disbursement;
      this.rex.offices[index].notifications = JSON.parse(JSON.stringify(this.rex.notifications));
      this.rex.offices[index].remittance = JSON.parse(JSON.stringify(this.rex.remittance));
      this.rex.offices[index].sendNotificationTo = "BOTH";
      this.rex.offices[index].sendRemittanceTo = "BOTH";
      this.rex.offices[index].appendPaymentType = this.rex.appendPaymentType;
    },

    isOfficeDisabled(data) {
      return (!data.canSync || !data.syncOffice);
    },

    addOfficeNotificationEmail(officeIndex) {
      this.rex.offices[officeIndex].notifications.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeNotificationEmail(officeIndex) {
      return this.rex.offices[officeIndex].notifications.length > 1;
    },

    removeOfficeNotificationEmail(officeIndex, index) {
      this.rex.offices[officeIndex].notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "notificationEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    addOfficeRemittanceEmail(officeIndex) {
      this.rex.offices[officeIndex].remittance.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeRemittanceEmail(officeIndex) {
      return this.rex.offices[officeIndex].remittance.length > 1;
    },

    removeOfficeRemittanceEmail(officeIndex, index) {
      this.rex.offices[officeIndex].remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "remittanceEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    async updateSettings() {
      let obj = {
        merchantId: this.partnerId,
        introducerId: this.introducerId,
      };

      if (this.activeIntroducer.isRexEnabled) {
        const validate = await this.$validator.validateAll();

        if (!validate || this.errors.items.length) {
          return false;
        }

        const connection = this.merchant.rex.connections.find((item) => item.introducerId == this.introducerId);
        const canSync = this.rex.offices.find((item) => item.canSync);
        const syncedOffice = this.rex.offices.filter((item) => item.canSync && item.syncOffice);

        if (canSync && (!syncedOffice || !syncedOffice.length)) {
          this.errors.add({
            field: "officeSync",
            msg: "Please sync atleast one office"
          });
          return false;
        }

        obj.rex = {
          introducerId: this.introducerId,
          parentId: connection.parentId,
          offices: []
        };

        obj.rex.offices.push({
          name: "default",
          disbursement: this.rex.disbursement,
          notifications: this.rex.notifications.map(item => { return item.email }).filter((item) => item),
          remittance: this.rex.remittance.map(item => { return item.email }).filter((item) => item),
          appendPaymentType: this.rex.appendPaymentType,
        });

        if (this.rex.offices && this.rex.offices.length) {
          const syncedOffice = this.rex.offices.filter((item) => item.canSync && item.syncOffice);
          syncedOffice.forEach((item) => {
            const payload = {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: (item.notifications.length && ["BOTH", "SELECTED_ADDRESS"].includes(item.sendNotificationTo)) ? item.notifications.map(item => { return item.email }).filter((item) => item) : [],
              remittance: (item.remittance.length && ["BOTH", "SELECTED_ADDRESS"].includes(item.sendRemittanceTo)) ? item.remittance.map(item => { return item.email }).filter((item) => item) : [],
              sendNotificationTo: item.sendNotificationTo,
              sendRemittanceTo: item.sendRemittanceTo,
              appendPaymentType: item.appendPaymentType,
            }

            obj.rex.offices.push(payload);
          });
        }

        obj.rex = JSON.stringify(obj.rex);
      }

      this.$vs.loading();
      
      await this.updateRexSettings(obj).then((res) => {
        this.$vs.loading.close();
        this.showToastMessage(res.data.title, res.data.message, "success");
        this.goBack();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showToastMessage("Integration settings", ex.data.message, "error");
      });
    },
  },
  
  mounted() {
    this.action = this.$route.params.type;

    if (this.action == "edit") {
      this.step = 2;
    }

    this.introducerId = this.$route.params.introducerId;
    this.checkAdminLogin();
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getIntroducerByMerchantId();
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return (this.user.userType === "admin") ? this.user._id : this.user.partnerId;
    },

    disableButtons() {
      let disable = (this.errors.items.length > 0);

      if (this.step == 1) {
        disable = !this.rex.name || !this.rex.email || !this.rex.syncOffices || !this.rex.terms || (this.errors.items.length > 0);
      }

      return disable;
    }
  }
}
</script>