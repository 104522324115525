<template>
  <div>
    <label>Terms & conditions</label>
    <div class="w-2/3 my-4 custom-quillEditor quill-editor integration-custom-terms">
      <div>
        <h4 style="font-size: medium;">
          <strong>
            PAYMENTS GENERAL TERMS& CONDITIONS
          </strong>
        </h4>
        <p class="mt-5">
          Flip Money Pty Ltd ACN 640 607 932 (“we”, “our”, “us”, “Flip” or “FlipMoney”) is a B2Bpayments
          technology company servicing Australian businesses. Flip has partnered by with Till Payments Solutions Pty Ltd
          ABN 64 160 726 349 (“Till Payments”) to provide Merchant Services which includes but not limited to: the
          acquiring, switching and processing of Card based transactions and transactions using other payment instruments
          via electronic funds transfer at point of sale(EFTPOS) or online.
          <br><br>
          The following Terms and Conditions set out and govern the Customer’s use of and access to Flip’s Services form
          part of the Merchant Services Agreement that includes these Terms, the Privacy Policy and the terms
          contained within the approved Merchant Application Form (the “Agreement”).Please read the Terms below carefully.
        </p>
      </div>
      <div class="mt-5">
        <ol>
          <li>
            <b>DEFINITIONS</b>
            <p>
              In addition to the terms defined throughout these General Terms,
              the following terms mean as follows:
            </p>
            <ol style="list-style-type: none;">
              <li>
                <strong>Acquiror:</strong> Till Payments Solutions Pty Ltd ABN 64 160 726 349 (“Till
                Payments”), the acquiring service provider.
              </li>
              <li>
                <strong>Agreement</strong>. means this Agreement, including the Merchant
                Application Form and its Schedules, and the
                <a href="https://flipmoney.com.au/privacy-policy/">
                  Flip Privacy Policy</a>.
                as published on our Website.
              </li>
              <li>
                <strong>Application Form</strong> means Customer’s online Flip Payments
                application form submitted to Flip and approved by Till Payments as
                the Acquiror.
              </li>
              <li>
                <strong>Authorisation:</strong> The confirmation by the card Issuer that the card
                number exists and that enough funds are available to allow the
                Transaction to go ahead.
              </li>
              <li>
                <strong>Business Day:</strong> A day other than Saturday or Sunday or public
                holiday in New South Wales.
              </li>
              <li>
                <strong>Card:</strong> A payment card or any form factor that can be used to initiate
                a payment transaction as specified on the Application.
              </li>
              <li>
                <strong>Cardholder:</strong> The individual whose name is embossed on a Card
                and any authorised user of such Card.
              </li>
              <li>
                <strong>Card Scheme:</strong> Any entity formed to administer and promote Cards,
                including without limitation MasterCard International Inc, Visa
                International Inc, eftpos Australia Limited.
              </li>
              <li>
                <strong>Card Scheme Rules:</strong> The rules, regulations, releases,
                interpretations and other requirements (whether contractual or
                otherwise) imposed or adopted by any Card Scheme.
              </li>
              <li>
                <strong>Commencement Date</strong> means the commencement date recorded
                in Schedule B of the Merchant Application Form.
              </li>
              <li>
                <strong>Confidential Information:</strong> means, in relation to either party, any
                information:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    relating to the terms of this Agreement;
                  </li>
                  <li>
                    relating directly or indirectly to research or development by,
                    accounting for, or the marketing of, the business of that party or
                    its suppliers or customers;
                  </li>
                  <li>
                    disclosed by that party to the other party on the express basis
                    that such information is confidential; or
                  </li>
                  <li>
                    which might reasonably be expected by the other party to be
                    confidential in nature;
                  </li>
                </ol>
              </li>
              <li>
                <strong>Control</strong> has the meaning given to it in Section 50AA of the
                Corporations Act 2001 (Cth);
              </li>
              <li>
                <strong>Customer</strong> means the party making a Request for Services and
                entering into this Agreement

                Dashboard means the online portal that Flip provides to the
                Customer as part of the Services;
              </li>
              <li>
                <strong>Documentation</strong> means any user, training or system manuals for Flip
                (whether in printed or electronic form) which describes and provides
                guidance on the use of Flip.
              </li>
              <li>
                <strong>Employees:</strong> Employees, contractors, officers, agents and
                secondees.
              </li>
              <li>
                <strong>Emergency Support</strong> means telephone support provided by Till
                Payments for emergency breakdowns resulting in repeated failures
                in the transmission of Transactions and is available 24 hours a day,
                seven days a week by calling 1300 369 692 outside of Support
                Hours.
              </li>
              <li>
                <strong>Fees</strong> means transaction fees and any other fees, costs or expenses
                payable by the Customer to pay Flip as detailed in Schedule B of
                the Merchant Application Form;
              </li>
              <li>
                <strong>General Terms:</strong> The terms and conditions set out in this document,
                as amended from time to time.
              </li>
              <li>
                <strong>Goods</strong> means the POS Terminal or devices issued to the Customer
                to process Card payments by the Acquiror.
              </li>
              <li>
                <strong>GST</strong> means GST as defined in the A New Tax System (Goods and
                Services Tax) Act 1999 (Cth), or any like tax.
              </li>
              <li>
                <strong>Insolvency Event:</strong> Any of the following:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    an order is made or an application is made to a court for an
                    order that a party be wound up;
                  </li>
                  <li>
                    an application is made to a court for an order appointing a
                    liquidator or provisional liquidator or a liquidator or provisional
                    liquidator is appointed in respect of a party;
                  </li>
                  <li>
                    a party enters into, or resolves to enter into, a scheme of
                    arrangement, deed of company arrangement or composition
                  </li>
                  <li>
                    with, or assignment for the benefit of, all or any classes of its
                    creditors, or it proposes a re-organisation, moratorium or
                  </li>
                  <li>
                    other administration involving any of them;
                  </li>
                  <li>
                    a party resolves to wind itself up, or otherwise dissolve itself, or
                    gives notice of intention to do so, except to reconstruct or
                    amalgamate while solvent on terms approved by the other party
                    or is otherwise wound up or dissolved;
                  </li>
                  <li>
                    a party is or states that it is unable to pay its debts when they
                    fall due;
                  </li>
                  <li>
                    a party takes any step to obtain protection or is granted
                    protection from its creditors, under any applicable legislation or
                    an administrator is appointed to a party; or
                  </li>
                  <li>
                    anything analogous or having a substantially similar direct effect
                    to any of the events specified in paragraphs (a)-(f) above
                    happens under the law of any applicable jurisdiction.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Intellectual Property</strong> means registered and unregistered
                trademarks (including logos and trade files), domain names,
                copyright, patents, petty patents, utility models, registered and
                unregistered designs, circuit layouts, rights in computer software,
                databases and lists, Confidential Information, software (whether in
                object code or source code), and all other rights anywhere in the
                world resulting from intellectual activity, whether registerable or not;
              </li>
              <li>
                <strong>Merchant:</strong> The party identified as the “Customer” on the Merchant
                Application Form. The words “you” and “your” refer to the Merchant
                or Customer.
              </li>
              <li>
                <strong>Merchant Services:</strong> the provision of sales, service and support to
                Merchants, including but not limited to: the acquiring, switching and
                processing of Card based transactions and transactions using other
                payment instruments via electronic funds transfer at point of sale
                (EFTPOS), or through the internet.
              </li>
              <li>
                <strong>PCI Standards</strong> means the Payment Card Industry standards,
                requirements and guidelines issued by the Payment Card Industry
                Data Security Council from time to time including the Payment Card
                Industry Data Security Standard PIN Entry Device requirements and
                guidelines, and the Payment Application Data Security Standard;
              </li>
              <li>
                <strong>Permitted Use</strong> means the transmission to, and receipt from, an
                Acquirer of data relating to Supported Transactions and expressly
                excludes, without limitation, use for the processing of transactions
                of, or for the benefit of, any person other than Customer;
              </li>
              <li>
                <strong>Personal Information</strong> means personal information as defined in the
                Privacy Act 1988 (Cth).
              </li>

              <li>
                <strong>PPSA</strong> means the Personal Property Securities Act 2009 (Cth).
                Related Company is a related entity that is part of the same
                ownership structure (either vertically or horizontally) as Customer,
                including a company incorporated outside Australia;
              </li>

              <li>
                <strong>POS Terminal:</strong> A point of sale device placed in a merchant location
                which is connected to the Till Payments system via telephone lines
                and is designed to authorise, record and settle data by electronic
                means for all sales Transactions with Till Payments.
              </li>

              <li>
                <strong>Privacy Law:</strong> The Privacy Act 1998 (Cth), any common law privacy
                obligations by which a party is bound and any industry code
                (whether voluntary or not) by which a party is bound.
              </li>

              <li>
                <strong>Request for Services</strong> means a request submitted by the Customer
                to Flip to use or have access to the Services;
              </li>

              <li>
                <strong>Security Interest</strong> means a a right, interest, power or arrangement in
                relation to any property which provides security for, or protects
                against default by a person in, the payment or satisfaction of a debt,
                obligation or liability, including a mortgage, charge, bill of sale,
                pledge, deposit, lien, encumbrance or hypothecation and a security
                interest as defined in section 12(1) and (2) of the PPSA.
              </li>

              <li>
                <strong>Security Standards</strong> means each of: any data protection or data
                security standards issued by an Acquirer which receives and
                accepts Supported Transactions from Customer; and the PCI
                Standards;
              </li>

              <li>
                <strong>Services</strong> means the services to be supplied to the Customer by Flip
                pursuant to the Customer’s Request for Services and may include
                the POS Terminal, online payments and payment gateway solutions.
              </li>

              <li>
                <strong>Software:</strong> All software, computer programs, related documentation,
                technology and processes embodied in or provided in connection
                with the Services.
              </li>

              <li>
                <strong>Support Hours</strong> means the period from 9am to 5pm (AEST) on any
                Business Day.
              </li>

              <li>
                <strong>Support Services</strong> means and includes:
                <ol style="list-style-type: lower-alpha;">
                  <li>
                    Online Help: from time to time we may display Frequently Asked
                    Questions and Answers on the Flip Website;
                  </li>
                  <li>
                    General Support: enquiries may be sent to:
                    support@flipmoney.com.au or made by telephone to 1300 354
                    766. We will respond to such enquiries during Support Hours;
                  </li>
                </ol>
              </li>
              <li>
                <strong>Till Payments</strong> means Till Payments Solutions Pty Limited (ABN: 64
                160 726 349), a company incorporated in Australia and the Acquiror
                with whom Flip has signed an exclusive partnership agreement with
                for the provision of Services.;
              </li>
              <li>
                <strong>Transaction:</strong> Includes a sales Transaction (being the supply of
                goods or services or both), a cash out Transaction, Refund, or Cash
                Related Transaction in which a Card or Card number is used and
                which is processed by the Merchant either manually or
                electronically
              </li>
              <li>
                <strong>Website</strong> means  <a href="https://www.flipmoney.com.au">www.flipmoney.com.au</a>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
}
</script>